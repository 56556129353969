import { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';

const LoginForm = (props) => {
    const { loading } = props

    const defaultData = {
        username: '',
        password: '',
        rememberLogin: false
      }

    const formData = props.initialData ? {...props.initialData} : defaultData

    const [loginDetails, setLoginDetails] = useState(formData)
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    const { username, password, rememberLogin } = loginDetails

    const handleChange = (event) => {
        const target = event.target
        const name = target.name

          setLoginDetails({
            ...loginDetails,
            [name]: target.value
          })
        }

    const handleCheckBoxChange = (event) => {
        const target = event.target
        const name = target.name

        setLoginDetails({
            ...loginDetails,
            [name]: target.checked ? true : false
        })
    }

    const validateForm = () => {
        return username.length > 0 && password.length > 0
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.handleFormSubmit({...loginDetails})
    }
    
    return (
        <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card card-signin my-5">
                    <div className="card-body">
                        <h3 className="card-title text-center mb-3">Sign In</h3>
                        <form className="form-signin" onSubmit={handleSubmit}>
                            <div className="form-label-group">
                                <input 
                                    type="text"
                                    autoComplete="username"
                                    value={username}
                                    onChange={handleChange}
                                    name="username"
                                    className="form-control mb-3" 
                                    placeholder="Username" 
                                    disabled={loading}
                                    required autoFocus />
                            </div>

                            <div className="input-group mb-3">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={handleChange}
                                    name="password"
                                    className="form-control rounded"
                                    placeholder="Password"
                                    disabled={loading}
                                    required
                                />
                                <div className="input-group-append">
                                    <button className="btn border-0" type="button" onClick={togglePasswordVisibility}>
                                        <i className={!showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}></i>
                                    </button>
                                </div>
                            </div>

                            {/* <div className="custom-control custom-checkbox mb-4" >
                                <input type="checkbox" 
                                        className="custom-control-input"
                                        id="customCheck"
                                        name="rememberLogin"
                                        onChange={handleCheckBoxChange}
                                        checked={rememberLogin} />
                                <label className="custom-control-label" htmlFor="customCheck">Remember password</label>
                            </div> */}
                            <div className="d-grid">
                                <button className="btn btn-lg btn-primary text-uppercase" type="submit" disabled={loading || !validateForm()}>
                                    { loading ? (
                                        <Spinner animation="border" variant="light" role="status" size="sm">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : (
                                    <>Login</>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginForm
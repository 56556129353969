import { useState, useEffect } from 'react';
import { useRouter } from 'next/router'
import LoginForm from '../components/loginForm';


const Login = (props) => {
    const { postLogin, auth, isAuth, query } = props
    const [loading, setLoading] = useState(null);

    const router = useRouter()
    useEffect(() => {
        if (loading) {
            const nextPath = typeof query.returnPath !== 'undefined' ? query.returnPath : '/';
            const nextQuery = typeof query.returnPath !== 'undefined' && typeof query.returnQuery !== 'undefined' ? JSON.parse(query.returnQuery) : {...query}
            postLogin(loading).then(() => {
                return router.replace({
                    pathname: nextPath,
                    query: nextQuery
                })
            }).catch((err) => {
                const errMsg = err?.response?.data ?? err
                console.error(errMsg);
                alert(errMsg);
                setLoading(null);
            });
        }

    }, [loading])

    useEffect(() => {
        return () => {
            setLoading(null);
        }
    }, [])
    
    return (
        <div className="container text-center">
            <LoginForm handleFormSubmit={ (loginForm) => setLoading(loginForm) } loading={ loading }/>
        </div>
    )
}

Login.getInitialProps = async (ctx) => {
    const { res, req, query } = ctx;
    if (res) {

        if (typeof res.req.session.user !== "undefined") {
            res.writeHead(302, {
                Location: '/',
                query: {...query}
            });
            res.end();

            return {isAuth: true, query}
        } else {
            return {isAuth: false, query};
        }
    } else {
        return {isAuth: false, query};
    }
    
}

export default Login
 